import React from 'react'
import * as S from './style'
import backgrounds from '../../assets/img/backgrounds'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import textPT from '../../assets/data/translate/conta-pj-pra-superar-os-desafios/text-pt.json'

const ContaPJPraSuperarOsDesafios = () => {
  const { title, CTA }: ISlideScrollSections = textPT

  return (
    <S.ModelsSectionBackground
      key='TESTE 4'
      className='colored d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection4.mobile}
      backgroundImage={backgrounds.backgroundHomeSection4.desktop}
      modelName='TESTE 4'
    >
      <div className='container-fluid'>
        <div className='row justify-content-md-end'>
          <div className='col-12 col-md-6 offset-md-6'>
            <S.TitleLarge as='h2' className='fw-400 f-sora text-white' dangerouslySetInnerHTML={{ __html: title }} />
            <div>
              <S.CTA
                to={CTA.link}
                className='text-white text-none'
                aria-label={CTA.text}
              >
                {CTA.text}
                <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
              </S.CTA>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default ContaPJPraSuperarOsDesafios
