import React from 'react'
import * as S from './style'
import backgrounds from '../../assets/img/backgrounds'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import { grayscale, white } from 'src/styles/colors'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import textPT from '../../assets/data/translate/busca-que-o-inter-tem/text-pt.json'

const BuscaQueOInterTem = React.forwardRef((props, ref) => {
  const width = useWidth()
  const { title, description, CTA }: ISlideScrollSections = textPT

  return (
    <S.ModelsSectionBackground
      key='TESTE 6'
      className='colored d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection6.mobile}
      backgroundImage={backgrounds.backgroundHomeSection6.desktop}
      ref={ref}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6'>
            <div className='ml-md-5 pl-md-5'>
              <S.TitleExtraLarge as='h2' className='fw-400 f-sora text-white' dangerouslySetInnerHTML={{ __html: title }} />
              <p className='fs-20 lh-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 f-sora fw-400 text-white my-3' dangerouslySetInnerHTML={{ __html: description }} />
              <div>
                <S.CTA
                  href={CTA.link}
                  className={`${width < WIDTH_MD ? 'text-white' : 'text-grayscale--500'}`}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={width < WIDTH_MD ? white : grayscale[500]} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default BuscaQueOInterTem
