import { Link } from 'gatsby'
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import { brand, grayscale } from 'src/styles/colors'
import { IModelsSectionBackground } from '../../types'

export const ModelsSectionBackground = styled('div')<IModelsSectionBackground>`
  height: 100vh;
  background-position: center;
  background-image: ${(props: IModelsSectionBackground) => `linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0)),url(${props.mobileBackgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 135px;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  @media ${device.tablet} {
    background-image: ${(props: IModelsSectionBackground) => `url(${props.backgroundImage})`};
    padding-bottom: 115px;
    background-position: center right -420px;
  }
  @media ${device.desktopLG} {
    padding-bottom: 0;
    background-position: center;
  }
`

export const Spacer = styled.div`
  height: 15vh;
  background: ${grayscale[500]};
`

export const TitleLarge = styled.h1`
  font-size: 36px;
  line-height: 45px;

  @media ${device.tablet} {
    font-size: 36px;
    line-height: 45px;
  }
  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 72px;
    line-height: 91px;
  }
`

export const TitleExtraLarge = styled.h1`
  font-size: 40px;
  line-height: 44px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }
  @media ${device.desktopLG} {
    font-size: 56px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 80px;
    line-height: 88px;
  }

  @media ${device.desktopXXXL} {
    font-size: 104px;
    line-height: 114px;
  }
`

export const CTA = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  &:focus {
    border: 2px solid;
  }
  &:hover {
    opacity: 0.7;
  }

  @media ${device.desktopXL} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 38px;
  }
`

export const ContainerVideo = styled.div`
  position: relative;
`

export const AbsoluteBlockText = styled.div`
  position: absolute;
  top: 50%;
  left: 41%;
  transform: translate(-50%, -50%);
`

export const HerobannerTag = styled.p`
  display: inline-block;
  color: white;
  font-weight: 600;
  border-radius: 70px;
  background-color: ${brand.primary};
  padding: 7px 14px;

  font-size: 18px;
  line-height: 20px;

  @media ${device.desktopLG} {
    padding: 10px 18px;
    font-size: 24px;
    line-height: 26px;
  }

  @media ${device.desktopXL} {
    padding: 14px 28px;
    font-size: 40px;
    line-height: 44px;
  }
  

  @media ${device.desktopXXLL} {
    padding: 20px 40px;
    font-size: 52px;
    line-height: 57px;
  }
`
