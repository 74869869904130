import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import SlideScrollSections from 'src/components/SlideScrollSections'

const Home = () => {
  return (
    <Layout pageContext={pageContext}>
      <SlideScrollSections />
    </Layout>
  )
}

export default Home
