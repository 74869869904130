import React, { useMemo } from 'react'
import useWidth from 'src/hooks/window/useWidth'

import { Section } from './style'

interface IVideoSrc {
  mobile: string;
  desktop: string;
}

type SupperVideoProps = {
  urlMp4: string;
  urlWeb: string;
  videoSrc?: string | IVideoSrc;
}

const SuperAppVideoUS = ({ urlMp4, urlWeb, videoSrc }: SupperVideoProps) => {
  const width = useWidth()

  const [ currentVideo, setCurrentVideo ] = React.useState('')

  const updateVideoSource = () => {
    const screenWidth = window.innerWidth
    const videoObject = videoSrc as IVideoSrc

    if (typeof videoObject !== 'string') {
      if (screenWidth >= 1024) {
        setCurrentVideo(videoObject.desktop)
      } else if (screenWidth >= 768) {
        setCurrentVideo(videoObject.desktop)
      } else {
        setCurrentVideo(videoObject.mobile)
      }
    } else {
      setCurrentVideo(String(videoSrc))
    }
  }

  React.useEffect(() => {
    updateVideoSource()

    window.addEventListener('resize', updateVideoSource)

    return () => window.removeEventListener('resize', updateVideoSource)
  }, [])

  const videoElement = useMemo(() => (
    <>
      <video
        key='introVideo'
        height='auto'
        width='100%'
        loop
        muted
        autoPlay
        playsInline
        data-autoplay-desktop='true'
        data-autoplay-portrait='true'
        data-autoplay-mobile='true'
        data-play-on-hover='false'
        src={currentVideo}
        preload='auto'
      >
        <source
          id='videoMp4'
          type='video/mp4'
          src={urlMp4}
        />
        <source
          id='videoWebm'
          type='video/webm'
          src={urlWeb}
        />
      </video>
    </>
  ), [ width ])

  return (
    <Section className='position-absolute d-md-flex align-items-md-center'>
      <div className='w-100 overflow-hidden'>
        {videoElement}
      </div>
    </Section>
  )
}

export default SuperAppVideoUS
