import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { IModelsSectionBackground } from '../../types'

export const Container = styled.div`

`

export const ModelsSectionBackground = styled('div')<IModelsSectionBackground>`
  background-position: center;
  background-image: ${(props: IModelsSectionBackground) => `url(${props.mobileBackgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-color: ${grayscale[500]};

  @media ${device.tablet} {
    background-image: ${(props: IModelsSectionBackground) => `url(${props.backgroundImage})`};
    padding-top: 40px;
  }
  @media ${device.desktopLG} {
    padding-top: 160px;
  }
  @media ${device.desktopXL} {
    padding-top: 200px;
  }
`
