import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  h1, h2, h3 {
    font-family: 'Citrina', sans-serif;
  }
`

export const Spacer = styled.div`
  height: 15vh;
  background: ${grayscale[500]};
`

export const TitleLarge = styled.h1`
  font-size: 36px;
  line-height: 45px;

  @media ${device.tablet} {
    font-size: 36px;
    line-height: 45px;
  }
  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 72px;
    line-height: 91px;
  }
`

export const TitleExtraLarge = styled.h1`
  font-size: 40px;
  line-height: 44px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }
  @media ${device.desktopLG} {
    font-size: 56px;
    line-height: 60px;
  }
  @media ${device.desktopXL} {
    font-size: 80px;
    line-height: 88px;
  }

  @media ${device.desktopXXXL} {
    font-size: 104px;
    line-height: 114px;
  }
`

export const CTA = styled.a`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  @media ${device.desktopXL} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 38px;
  }
`

export const ContainerVideo = styled.div`
  position: relative;
`

export const AbsoluteBlockText = styled.div`
  position: absolute;
  top: 50%;
  left: 41%;
  transform: translate(-50%, -50%);
`
