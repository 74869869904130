import gifUmaVidaFinanceiraDesktop from './gif/gif-uma-vida-financeira-desktop.gif'
import gifUmaVidaFinanceiraMobile from './gif/gif-uma-vida-financeira-mobile.gif'

const backgrounds = {
  backgroundHomeSection1: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-image-home-institucional-sm/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-image-home-institucional-xxxl/image.webp',
  },
  backgroundHomeSection2: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-seguranca-pra-tudo-mobile/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-seguranca-pra-tudo-desktop/image.webp',
  },
  backgroundHomeSection3: {
    mobile: gifUmaVidaFinanceiraMobile,
    desktop: gifUmaVidaFinanceiraDesktop,
  },
  backgroundHomeSection4: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/home-cartaopj-dobra4-360/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/home-cartaopj-dobra4-1920/image.webp',
  },
  backgroundHomeSection5: {
    mobile: 'https://central-imagens.bancointer.com.br/gif/home-cartaopj/arquivo.webm',
    desktop: 'https://central-imagens.bancointer.com.br/gif/home-cartaopj/arquivo.webm',
  },
  backgroundHomeSection6: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-mobile-home/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/site-dobra6/image.webp',
  },
  backgroundHomeSection7: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-home-nova/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-home-nova-2/image.webp',
  },
  backgroundHomeSection8: {
    mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-home-secion-8-mobile/image.webp',
    desktop: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-home-secion-8/image.webp',
  },
}

export default backgrounds
