import React from 'react'

import Footer from 'components/Layout/Footer'
import * as S from './style'

const HomeFooter = () => {
  return (
    <S.ModelsSectionBackground className='d-flex justify-content-center align-items-end'>
      <Footer theme='dark' />
    </S.ModelsSectionBackground>
  )
}

export default HomeFooter
