import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 256px;

  video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;

    @media ${device.tablet} {
      object-fit: contain;
    }

    @media ${device.desktopLG} {
      object-fit: cover;
    }
  }

  .video-container {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    @media ${device.desktopXXXL} {
      top: 145px;
    }
  }

  .video-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
    pointer-events: none; // Allows interaction with the video underneath

    @media ${device.tablet} {
      content: none;
    }
  }

  @media ${device.tablet} {
    align-items: center;
  }

  button {
    background: ${orange.extra};
    max-width: 110px;
    width: 100%;
    height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    border-radius: 8px;

    @media ${device.tablet} {
      max-width: 323px;
      height: 49px;
    }
  }
`
export const Card = styled.div`
  .space-store {
    padding-top: 20px;
  }
`
