import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import textPT from '../../assets/data/translate/um-super-app/text-pt.json'
import backgrounds from '../../assets/img/backgrounds'
import * as S from './style'

const UmSuperApp = React.forwardRef((props: { setIsOpen: Function }, ref) => {
  const { title, CTA }: ISlideScrollSections = textPT
  const windowWidth = useWidth(300)
  const { setIsOpen }: { setIsOpen: Function } = props

  return (
    <S.ModelsSectionBackground
      key='TESTE'
      className=' d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection1.mobile}
      backgroundImage={backgrounds.backgroundHomeSection1.desktop}
      ref={ref}
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='ml-md-5 pl-xl-5'>
              <S.TitleExtraLarge
                className={`${windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'} fw-400 f-sora`}
                dangerouslySetInnerHTML={{ __html: title }}
                aria-label={title}
              />
              <div className='my-3'>
                <S.HerobannerTag className='mb-0'>E da Tatá também.</S.HerobannerTag>
              </div>
              <div>
                <S.CTA
                  as='button'
                  onClick={() => setIsOpen(true)} aria-label={CTA.text}
                  className={windowWidth < WIDTH_MD ? 'text-white' : 'text-grayscale--500'}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={windowWidth < WIDTH_MD ? '#FFFFFF' : '#161616'} className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
})

export default UmSuperApp
